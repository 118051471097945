<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 text-left w-full">
      <h2 class="text-xl font-bold">Default </h2>
      <asom-card title="Card tile" size="md">
        Body content
      </asom-card>
    </div>
    <div class="space-y-4 pb-10">
      <asom-card v-for="(s, i) in sizes" :key="i" :size="s" :title="`Card size ${s}`">
        <p class="text-gray-700 text-base">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
        </p>
      </asom-card>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      sizes: [ 'sm', 'md', 'lg', 'xl', '2xl', 'full']
    }
  }
}
</script>